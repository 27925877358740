import React, { useState, useEffect } from "react";
import Link from "./library/Link";
import { css } from "@emotion/core";
import { primaryColor, mq } from "../theme";
import TransitionLink from "gatsby-plugin-transition-link";

import hamburgerIcon from "../../static/img/icons/hamburger.svg";
import pawIcon from "../../static/img/icons/paw.svg";

const navbarStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: #fff;

  z-index: 5001;

  transition-duration: 0.2s;

  &.shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const navigationStyles = css`
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fff;

  opacity: 0;
  transform: translateY(-25px);
  transition-duration: 0.3s;
  pointer-events: none;

  /* Display below the naviagtion background */
  z-index: -2;

  &.open {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    transition-duration: 0.5s;
  }

  ${mq("large")} {
    position: absolute;

    top: 0;
    right: 24px;

    flex-direction: row;
    justify-content: flex-end;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);

    z-index: 0;
    background-color: transparent;
  }
`;

const navItemStyles = css`
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;

  padding: 16px;

  font-family: "Raleway";
  font-size: 36px;
  font-weight: lighter;
  text-decoration: none;

  color: ${primaryColor};

  &:before {
    content: "";

    position: absolute;
    left: -24px;
    top: 50%;

    width: 24px;
    height: 24px;

    transform: translate(0, -50%);
  }

  &.active {
    &:before {
      background-image: url(${pawIcon});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  ${mq("large")} {
    font-size: 24px;

    &:before {
      content: none;
    }

    &:after {
      content: "";

      position: absolute;

      bottom: 12px;
      left: 50%;
      width: 80%;

      height: 2px;

      background-color: ${primaryColor};
      opacity: 0;
      transform: translate(-50%) scaleX(0);
      transition-duration: 0.3s;
    }

    &.active:after {
      opacity: 1;
      transform: translate(-50%) scale(1);
    }
  }
`;

const hamburgerStyles = css`
  position: absolute;
  top: 50%;
  left: 20px;

  cursor: pointer;
  transform: translate(0, -50%);
  border: none;
  background: none;

  &:hover {
    background: none;
  }

  ${mq("large")} {
    display: none;
  }
`;

const logoWrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${primaryColor};
  text-decoration: none;

  ${mq("large")} {
    position: absolute;
    top: 50%;
    left: 24px;

    transform: translate(0, -50%);
    z-index: 1;
  }
`;

const logoStyles = css`
  margin: 0;

  font-family: "Sacramento", cursive;
  font-size: 22px;
  text-align: center;
`;

const logoTagStyles = css`
  font-size: 10px;
  transform: translateY(-4px);
`;

const NavLink = ({ to, children, ...props }) => {
  return (
    <TransitionLink
      css={navItemStyles}
      activeClassName="active"
      className="navbar-item"
      to={to}
      exit={{
        length: 0.5,
      }}
      entry={{
        delay: 0.5,
      }}
      {...props}
    >
      {children}
    </TransitionLink>
  );
};

const Navbar = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNav = () => {
    // toggle the active boolean in the state
    setNavOpen(!isNavOpen);
  };

  return (
    <nav
      css={navbarStyles}
      className={`${isScrolled ? "shadow" : ""}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div>
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: #fff;

            z-index: -1;
          `}
        />
        <button css={hamburgerStyles} onClick={toggleNav}>
          <img src={hamburgerIcon} alt="Navigation icon" />
        </button>
        <Link to="/" css={logoWrapperStyles}>
          <div css={logoStyles}>CAYdoodles</div>
          <span css={logoTagStyles}>/kī/doodles</span>
        </Link>
        <div css={navigationStyles} className={`${isNavOpen ? "open" : ""}`}>
          <NavLink to="/" onClick={toggleNav}>
            Home
          </NavLink>
          <NavLink to="/puppies" partiallyActive={true} onClick={toggleNav}>
            Puppies
          </NavLink>
          <NavLink to="/about" onClick={toggleNav}>
            About
          </NavLink>
          <NavLink to="/our-dogs" onClick={toggleNav}>
            Our Dogs
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
