export const primaryColor = '#4e4e4e';
export const primaryColorDark = '#3a3a3a';
export const PrimaryColorLight = '#808080';

export const accentColor = '#dda2a9';

const breakpoints = {
  medium: 450,
  large: 1024,
};

export const mq = name => {
  const bpArr = Object.keys(breakpoints).map(size => [size, breakpoints[size]]);

  const [query] = bpArr.reduce((acc, [size, width]) => {
    if (name === size) {
      return [...acc, `@media (min-width: ${width}px)`];
    }
    return acc;
  }, []);

  return query;
};
