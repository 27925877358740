import React, { useRef } from "react";
import Helmet from "react-helmet";
import Navbar from "../components/Navbar";
import { css } from "@emotion/core";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import Footer from "./Footer";

export const FloatWrapContext = React.createContext();

const wrapperStyles = css`
  margin-top: 56px;
`;

const floatWrapStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 10000;

  &:empty {
    pointer-events: none;
  }
`;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const wrapperRef = useRef(null);

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/fav/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/img/fav/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/img/fav/favicon-16x16.png"
        />
        <link rel="manifest" href="/img/fav/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/img/fav/safari-pinned-tab.svg"
          color="#dda2a9"
        />
        <link rel="shortcut icon" href="/img/fav/favicon.ico" />
        <meta name="msapplication-TileColor" content="#dda2a9" />
        <meta
          name="msapplication-config"
          content="/img/fav/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Navbar />
      <FloatWrapContext.Provider value={wrapperRef}>
        <main css={wrapperStyles}>{children}</main>
      </FloatWrapContext.Provider>
      <Footer />
      <div css={floatWrapStyles} ref={wrapperRef} />
    </div>
  );
};

export default TemplateWrapper;
