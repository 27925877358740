import React from "react";
import { css } from "@emotion/core";

const footerContainerStyles = css`
  padding: 16px;
`;

export default function Footer() {
  return <div css={footerContainerStyles} />;
}
