import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";

const Link = (props) => (
  <TransitionLink
    exit={{
      length: 0.5,
    }}
    entry={{
      delay: 0.5,
    }}
    {...props}
  />
);

export default Link;
